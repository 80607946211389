// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import Map from '../images/Multi-Scale Integrated Cell (MuSIC) v1.png';
import styles from './pageStyles/index.module.css';


const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const DonwloadData = (props: PageProps) => (
  <Layout>
    <SEO title='Download Data' />
   
    <TopPanel>
      <HorizontalTitle title='Browse Data' subtitle='U2OS Cell Map' />

    </TopPanel>

    <Panel
    >
      <a id='data'>
      </a>
      <p>
        <ul>          
          <li>
          The U2OS cell map 
          <ol>   The U2OS MuSIC map  contains 5,147 proteins organized into 275 subsystems. This global map of subcellular structure was generated by integrating immunofluorescent images with biophysical interactions derived from AP-MS. Note: this unpublished map is provided as a resource.
</ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=1kjqrwZ0zjaawxJkukxmZOnFeibGnEFmM'> U2OS_cell_map.cx
             </LinkOut>
             {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://www.ndexbio.org/viewer/networks/7baaa003-200c-11ee-aa50-005056ae23aa'> Download
             </LinkOut> */}
          </ol>
          </li>
          <li>
          U2OS cell map subsystems 
          <ol>   The 275 subsystems in the U2OS cell map in tabular format. Each row represents a subsystem in the map with information on enrichment analyses, subsystem genes, and links to subnetworks. The second tab describes the information in each column.  </ol> 
          <ol> 
          <LinkOut link='https://docs.google.com/spreadsheets/d/1vo2BLr7o5k14QfuhmwGr1SoxYZ_MZBZp0pz9Plgg5Ug'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            <LinkOut link='https://docs.google.com/spreadsheets/d/1vo2BLr7o5k14QfuhmwGr1SoxYZ_MZBZp0pz9Plgg5Ug/export?format=xlsx'> Download
            </LinkOut>
          </ol>
          </li>
          <li>
            U2OS immunofluorescent images
          <ol> There are 20,660 immunofluorescent images for 10,348 proteins in the U2OS cell line. This file contains a link to every RGB image used in the U2OS cell map and the corresponding gene name and antibody.  </ol> 
          <ol>
          <LinkOut link='https://docs.google.com/spreadsheets/d/1VgnboMiMqNO7kJRQb7Z1t73fnhbMRNDUJXiBZc36HEE/edit#gid=1156624013'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            <LinkOut link='https://drive.google.com/uc?export=download&id=1OKwQkUUXIicl_cq9EvzvtdLiBOtJT853'> Download
            </LinkOut>
            {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1g5ALFQwo7V3qds29MXwH7LPcQf-XSG3U'> Download .tsv
            </LinkOut> */}
          </ol>
          </li>
          <li>
          <li>
           U2OS biophysical interactions
          <ol>   The U2OS biophysical interaction network contains 36,842 edges between 7,543 unique proteins. This interaction network was derived from affinity purification-mass spectrometry experiments. </ol> 
          <ol>
          <LinkOut link='https://www.ndexbio.org/viewer/networks/95bc75d5-d1d1-11ee-8a40-005056ae23aa'> View on NDEx
            </LinkOut>

           
          </ol>
          </li>
          {/* <li>
            Inputs to toolkit
          <ol> These are the files input to the {' '}<LinkOut link='https://cellmaps-pipeline.readthedocs.io/en/latest/'> cell mapping toolkit</LinkOut> used to construct the U2OS MuSIC map. The files include a list of antibodies to use for each protein (unique), information for image download (samples), protein interactions (edges), and protein interaction baits (baits).  </ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=1iIOCGGO0Z3VCB-fQZUtfaGZsvQgYPlI2'> U2OS_unique.csv
            </LinkOut>
            &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1QAvAeX5TLM98D_VF_NYyGPJd1gxrNeip'> U2OS_samples.csv
            </LinkOut>
          </ol>
          </li> */}
          </li>
          <li>
            U2OS size exclusion chromatography data
          <ol> There are three technical replicates of SEC-MS data. These files contain the protein quantification results for each replicate. MS raw files are available via Proteome Xchange with the identifier PXD052362. </ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=1alXbAgORr_torZazm0w2Oaj5rWbTtDFR'> Download
            </LinkOut>
          </ol>
          </li>
          </ul>
      </p>
    </Panel>

  </Layout>
);

export default DonwloadData;
